import React from 'react'
import { connect } from 'react-redux';
import { Menu, Icon } from "semantic-ui-react";
import { sucursal_from_state } from '../reducers';

import { CONFIGURATION, DASHBOARD, COMPRAS, JORNADAS, SUCURSALES } from '../utils/Routes'

function Footer({ history, location, nombre_sucursal }) {
  const go = (v) => history.push(v)
  const path = location.pathname

  return (
    <Menu fixed="bottom" fluid inverted icon="labeled" widths={5}>
      <FooterItem label="Pedidos" icon="inbox" active={path === DASHBOARD} onClick={() => go(DASHBOARD)} />
      <FooterItem label="Gastos" icon="book" active={path === COMPRAS} onClick={() => go(COMPRAS)}/>
      <FooterItem label="Jornadas" icon="balance scale" active={path === JORNADAS} onClick={() => go(JORNADAS)}/>
      <FooterItem label="Ajustes" icon="cog" active={path === CONFIGURATION} onClick={() => go(CONFIGURATION)}/>
      <FooterItem label={nombre_sucursal} icon="exchange" active={path === SUCURSALES} onClick={() => go(SUCURSALES)}/>
    </Menu>
  )
}

const FooterItem = ({ label, icon, active, onClick }) => (
  <Menu.Item active={active} onClick={onClick}>
    <Icon name={icon} />
    {label}
  </Menu.Item>
)

const mapStateToProps = (state) => {
  const sucursal = sucursal_from_state(state)
  return {
    nombre_sucursal: sucursal ? sucursal.nombre : ''
  } 
}

export default connect(mapStateToProps)(Footer)
import React from 'react';
import Screen from './Screen'
import { connect } from "react-redux";
import { Menu, Button, Icon, Container, Form, Select, Item } from "semantic-ui-react";
import isElectron from "is-electron";

import epson from '../lib/EpsonDevice'
import { InputTelefono, InputRun, SolicitarPin } from '../components'
import { EFECTIVO, mediosDePago } from '../utils/PaymentTypes'
import {DELIVERY, is_delivery, LOCAL, RESTAURANT, RETIRO} from '../utils/OrderTypes'
import { isEmpty, rutValido, numeric, int, fecha, reduceProducts, is_printer_browser } from '../utils'
import { DASHBOARD } from '../utils/Routes'
import { ingresarPedido, modificarPedido } from '../api'
import { ComandaPrintRender, comandaTemplate } from '../lib/EpsonTemplate'
import { ItemProducto, ProductosModal } from '../components/Product';
import { pedidos_jornada_from_state } from '../reducers/JornadaReducer';
import { sucursal_from_state } from '../reducers';
import ReactToPrint from 'react-to-print';
import { electron_imprimir } from '../lib/Electron';



function productosIguales(a, b) {
  if (a.length !== b.length) {
    return false
  }

  for (let i = 0; i < a.length; i++) {
    if (a[i]._id !== b[i]._id || a[i].quantity !== b[i].quantity) {
      return false
    }
  }

  return true
}

const Delivery = () => {
  let d = []
  for (var i = 500; i <= 10000; i += 500) {
    d.push({
      key: i,
      value: i,
      text: numeric(i.toString())
    })
  }
  return d
}

const DefaultRepartidor = { key: -1, value: null, text: "Selecciona al repartidor" }

class NuevoPedido extends Screen {
  state = {
    run: '',
    phone: '',
    address: '',
    name: '',
    apellido: '',
    comment: '',
    payment: EFECTIVO,
    type: LOCAL,
    delivery: 0,
    empleado: '',
    products: [],
    loading: true,
    customers: [],
    error: '',
    modalProductos: false,
    modalPin: false
  }

  async componentDidMount() {
    if (!isElectron() && !is_printer_browser(this.props.printer_modo)) {
      try {
        console.log('init printer', this.props.printer_target, this.props.printer_ssl)
        this._printer = await epson.print(this.props.printer_target, this.props.printer_ssl)
      } catch (e) {
        this.setState({ error: 'Impresora: ' + e.message })
        console.log(e)
      }
    }
    this.setState({ loading: false })

    const id = this.props.match.params.id
    if (!id) {
      return
    }

    try {
      let pedido = this.obtenerPedido()
      if (pedido) {
        this.setState({
          id_cliente: pedido.user.id,
          run: pedido.user.run || '',
          phone: pedido.user.phone || '',
          address: pedido.user.address || '',
          id_direccion: pedido.user.id_direccion,
          apellido: pedido.user.apellido || '',
          name: pedido.user.name || '',
          comment: pedido.comment || '',
          payment: pedido.payment || EFECTIVO,
          type: pedido.type || LOCAL,
          empleado: pedido.deliveryMan ? pedido.deliveryMan.email : '',
          delivery: pedido.deliveryCost || 0,
          products: [...pedido.products]
        })
      }
      console.log(pedido)
    } catch (e) {
      console.log(e)
    }
  }

  obtenerPedido = () => {
    const id = this.props.match.params.id
    let pedido = this.props.pedidos.find(v => v._id === id)
    if (!pedido) {
      pedido = this.props.pedidos_jornada.find(v => v._id === id)
    }

    return pedido
  }

  verificar = () => {
    const pedido = this.obtenerPedido()
    if (pedido) {
      const { products, user, type, payment, deliveryCost } = pedido
      let iguales = productosIguales(products, this.state.products)
      iguales = iguales && type === this.state.type
      iguales = iguales && payment === this.state.payment
      iguales = iguales && deliveryCost === int(this.state.delivery)
      iguales = iguales && user.phone === this.state.phone
      iguales = iguales && user.address === this.state.address
      iguales = iguales && user.run === this.state.run

      if (!iguales) {
        return this.togglePin()
      }
    }
    this.submit()
  }

  pedidoImprimir = () => {
    const deliveryCost = is_delivery(this.state.type) ? int(this.state.delivery) : 0
    const pedido = {
      user: {
        phone: this.state.phone.trim(),
        name: this.state.name.trim(),
        apellido: this.state.apellido.trim(),
        address: this.state.address.trim(),
      },
      comment: this.state.comment,
      products: [...this.state.products],
      deliveryCost
    }

    return {
      order: pedido,
      sucursal: this.props.sucursal
    }
  }

  submit = (pin) => {
    const deliveryCost = is_delivery(this.state.type) ? int(this.state.delivery) : 0
    
    const _pedido = this.obtenerPedido()
    const pedido = {
      pin,
      _id: _pedido && _pedido._id,
      user: { 
        id: this.state.id_cliente,
        run: this.state.run.trim(),
        phone: this.state.phone.trim(),
        name: this.state.name.trim(),
        apellido: this.state.apellido.trim(),
        address: this.state.address.trim(),
        despacho: deliveryCost,
        id_direccion: this.state.id_direccion
      },
      comment: this.state.comment,
      type: this.state.type,
      payment: this.state.payment,
      deliveryMan: this.props.empleados.find(v => v.email === this.state.empleado),
      products: [ ...this.state.products ],
      deliveryCost
    }
    this.setState({ loading: true })
    const fnc = _pedido ? modificarPedido : ingresarPedido
    console.log(pedido)
    fnc(pedido).then(res => {
      if (res.status === 'success') {
        if (!_pedido && is_delivery(this.state.type)) {
          this.comanda(pedido)
        }
        this.back()
      } else {
        this.setState({ error: res.data })
      }
    }).catch(e => {
      console.error('ingresar pedido', e)
      this.setState({ error: 'Error interno', loading: false })
    })
  }

  comanda = (order) => {
    console.log('printing comanda')

    if (isElectron()) {
      const pedido = {
        order,
        sucursal: this.props.sucursal
      }
      electron_imprimir(this.props.printer_target, pedido)
      return
    }

    if (is_printer_browser(this.props.printer_modo)) {
      this.printPedidoRef.handlePrint()
    } else if (this._printer && this._printer !== null) {
      const pedido = {
        order,
        sucursal: this.props.sucursal
      }
      comandaTemplate(this._printer, pedido)
    }
    /*const promises = []
    for (let i = 0; i < quantity; i++) {
      promises.push(comandaTemplate(printer, order))
    }
    Promise.all(promises).then(console.log).catch(console.error)*/

  }

  onPrintClick = () => {
    this.comanda(this.obtenerPedido())
  }

  addProduct = (_id) => {
    if (this.state.products.find(p => p._id === _id)) {
      this.setState({
        products: this.state.products.map(p => ({ ...p, quantity: p._id === _id ? p.quantity + 1 : p.quantity }))
      })
    } else {
      const product = this.props.productos.find(v => v._id === _id)
      this.setState({
        products: [...this.state.products, { ...product, quantity: 1 }]
      })
    }
  }

  plusProduct = (id) => {
    this.setState({
      products: this.state.products.map(p => ({ ...p, quantity: p._id === id ? p.quantity + 1 : p.quantity}))
    })
  }

  minusProduct = (id) => {
    this.setState({ 
      products: this.state.products.map(p => ({...p, quantity: p._id === id ? p.quantity - 1 : p.quantity})).filter(p => p.quantity > 0)
    })
  }

  removeProduct = (e, _id) => {
    e.preventDefault()
    this.setState({ products: this.state.products.filter(p => p._id !== _id) })
  }

  onPhoneSelect = ({ id, nombre, apellido, direccion, id_direccion, telefono, despacho }) => {
    this.setState({
      id_cliente: id,
      phone: telefono,
      name: nombre,
      apellido,
      address: direccion,
      delivery: despacho,
      id_direccion
    })
  }

  _payment = (e, value) => {
    e.preventDefault()
    this.setState({payment: value})
  }

  _type = (value) => {
    this.setState({ type: value })
  }

  _onChange = (e) => {
    this.setState({[e.target.id]: e.target.value})
  }

  onRunValido = (puntos, cliente) => {
    console.log(puntos, cliente)
  }

  selectExtra = (value, _id) => {
    console.log(value, _id)
    this.setState({ products: this.state.products.map(p => p._id !== _id ? p : ({
      ...p,
      extras: p.extras.map(e => ({ ...e, selected: value === e.value }))
    }))})
  }

  toggleProductos = (e) => {
    if (e) {
      e.preventDefault()
    }
    this.setState({ modalProductos: !this.state.modalProductos })
  }

  togglePin = (e) => {
    if (e) {
      e.preventDefault()
    }
    this.setState({ modalPin: !this.state.modalPin })
  }

  render() {
    const { payment, phone, run, name, apellido, address, delivery, type, empleado, loading, products, modalProductos } = this.state
    const isDelivery = type === DELIVERY
    const invalidRun = !isEmpty(run) && !rutValido(run)
    const disabled = (isDelivery && (isEmpty(address) || int(delivery) === 0)) || isEmpty(name) || products.length === 0 ||
      isEmpty(apellido) || (type !== RESTAURANT && isEmpty(phone)) || invalidRun || loading
    const deliveryOptions = [DefaultRepartidor, ...this.props.empleados.map(v => ({ key: v.email, text: v.nombre, value: v.email }))]
    const total = this.state.products.reduce(reduceProducts, this.state.delivery)
    const pedido_existe = this.obtenerPedido()


    return (
      <div>
        <Menu inverted icon="labeled" fixed="top" fluid>
          <Menu.Item onClick={this.back}>
            <Icon name="chevron left" size="big"/>
            Volver
          </Menu.Item>
          {pedido_existe && <Menu.Item>
            <div>
              {fecha(pedido_existe.datetime)}
            </div>
          </Menu.Item>}
          <Menu.Menu position="right" >
            {pedido_existe && isDelivery && <Menu.Item onClick={this.onPrintClick} disabled={loading}>
              <Icon name="print" size="big" />
              Imprimir
            </Menu.Item>}
            <Menu.Item onClick={this.toggleProductos}>
              <Icon name="plus" size="big" />
              Agregar Producto
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <Container>
          <Form size="big" loading={loading}>
            <InputRun id="run" value={run} onChange={this.onChange} onRunValido={this.onRunValido} />
            <InputTelefono value={phone} onChange={phone => this.setState({phone})} onSelect={this.onPhoneSelect} required={type !== RESTAURANT}/>
            <Form.Input label="Dirección" id="address" icon="map marker" iconPosition="left" placeholder="Direccion de despacho"
              onChange={this._onChange} value={address} required={type === DELIVERY}/>
            <Form.Input label="Nombre" id="name" icon="user" iconPosition="left" placeholder="Nombre"
              onChange={this._onChange} value={name} required />
            <Form.Input label="Apellido" id="apellido" icon="user" iconPosition="left"  placeholder="Apellido"
              onChange={this._onChange} value={apellido} required />
            <Form.TextArea label="Comentario" id="comment" onChange={this._onChange} value={this.state.comment}  />
            <Form.Field
              control={Select}
              label='Medio de pago'
              options={mediosDePago}
              value={payment}
              onChange={(e, { value }) => this.setState({ payment: value })}
            />
            <Form.Field>
              <Menu compact icon='labeled' widths={3}>
                <Menu.Item name='gamepad' active={type === LOCAL || type === RETIRO} color="red" onClick={() => this._type(LOCAL)}>
                  <Icon name='home' />
                  Retiro
                </Menu.Item>
                <Menu.Item name='gamepad' active={type === DELIVERY} color="red" onClick={() => this._type(DELIVERY)}>
                  <Icon name='motorcycle' />
                  Despacho
                </Menu.Item>
                <Menu.Item name='gamepad' active={type === RESTAURANT} color="red" onClick={() => this._type(RESTAURANT)}>
                  <Icon name='utensils' />
                  Local
                </Menu.Item>
              </Menu>
            </Form.Field>
            {type === DELIVERY &&
              <Form.Field
                control={Select}
                label='Seleccione al repartido'
                options={deliveryOptions}
                placeholder='Seleccione al repartidor'
                value={empleado}
                onChange={(e, { value }) => this.setState({ empleado: value })} />
            }
            {type === DELIVERY &&
              <Form.Field
                required
                control={Select}
                label='Costo de Despacho'
                options={Delivery()}
                placeholder='Selecciona el costo de despacho'
                value={delivery}
              onChange={(e, { value }) => this.setState({ delivery: value })} />
            }
            <Item.Group divided>
              {products.map(p =>
                <ItemProducto key={p._id} {...p} plus={this.plusProduct} minus={this.minusProduct} selectExtra={this.selectExtra} />
              )}
              <Item>
                <Item.Content style={{ textAlign: 'center'}}>
                  <h2><a href="" onClick={this.toggleProductos}>+ Agregar productos</a></h2>
                </Item.Content>
              </Item>
            </Item.Group>
            <div style={{ textAlign: 'right', padding: 8 }}>
              {type === DELIVERY && (
                <h3>Costo despacho: ${numeric(delivery)}</h3>
              )}
              <h2>Total del Pedido: ${numeric(total)}</h2>
            </div>
            <Button primary loading={this.state.loading} fluid disabled={disabled} onClick={this.verificar} size="big">
              Registrar Pedido ${numeric(total)}
            </Button>
          </Form>
        </Container>
        <ProductosModal visible={modalProductos} toggle={this.toggleProductos} onClick={this.addProduct} />
        <SolicitarPin onClick={this.submit} visible={this.state.modalPin} toggle={this.togglePin} />
        <ReactToPrint ref={el => (this.printPedidoRef = el)} content={() => this.pedidoRef} />
        <ComandaPrintRender ref={(el) => (this.pedidoRef = el)} data={this.pedidoImprimir()} />
      </div>
    );
  }
}

const mapStateToProps = ({ productos, pedidos, empleados, jornada_detalle, ajustes, session }) => ({
  productos, pedidos, empleados,
  pedidos_jornada: pedidos_jornada_from_state({ jornada_detalle }),
  printer_target: ajustes.printer_target,
  printer_quantity: ajustes.printer_quantity,
  printer_ssl: ajustes.printer_ssl,
  printer_modo: ajustes.printer_modo,
  sucursal: sucursal_from_state({ session })
})

export default connect(mapStateToProps)(NuevoPedido)
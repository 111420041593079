import React, { Component } from 'react';

import { INGRESAR_COMPRA } from '../../utils/Routes'
import { fetchGastos, isSuccess, eliminarGasto } from "../../api";
import { Button, Container, Icon, Item, Menu, Modal } from 'semantic-ui-react';
import GastoItem from '../../components/GastoItem';


export default class Gastos extends Component {
  state = {
    gastos: [],
    modal: false,
    loading: false
  }

  componentDidMount() {
    this.fetch()
  }

  confirmar = (id) => {
    this._id_gasto = id
    this.toggle()
  }

  eliminar = () => {
    this.toggle()
    eliminarGasto(this._id_gasto).then(this.fetch).catch(console.error)
  }

  create = () => this.props.history.push(INGRESAR_COMPRA)

  fetch = () => fetchGastos().then(res => {
    if (isSuccess(res)) {
      this.setState({ gastos: res.data })
    } else {
      this.setState({ gastos: [] })
    }
  })

  toggle = () => this.setState({ modal: !this.state.modal })

  render() {
    const data = this.state.gastos.sort(sortGastos)
    return (
      <div>
        <Menu inverted icon="labeled" fixed="top" fluid>
          <Menu.Item>
            <Icon name="book" />
            Gastos
          </Menu.Item>
          <Menu.Item position="right" onClick={this.create}>
            <Icon name="plus" />
            Ingresar Gasto
          </Menu.Item>
        </Menu>
        <Container>
          <Item.Group divided>
            {data.map(g => <GastoItem key={g.id} { ...g } eliminar={this.confirmar} />)}
          </Item.Group>
        </Container>
        <Confirmar onClick={this.eliminar} visible={this.state.modal} toggle={this.toggle} />
      </div>
    )
  }
}

const sortGastos = (a, b) => b.id - a.id

const Confirmar = ({ onClick, visible, toggle }) => (
  <Modal open={visible} onClose={toggle}>
    <Modal.Header>Eliminar Gasto</Modal.Header>
    <Modal.Content>Para continuar eliminando el gasto presiona continuar</Modal.Content>
    <Modal.Actions>
      <Button onClick={toggle}>Volver</Button>
      <Button onClick={onClick} color="red">Continuar</Button>
    </Modal.Actions>
  </Modal>
)
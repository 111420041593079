import createReducer from './createReducer'
import { FIJAR_JORNADAS, FIJAR_JORNADA_ACTIVA, FIJAR_JORNADA_DETALLE } from '../actions/Types'


export const jornadas = createReducer([], {
  [FIJAR_JORNADAS]: (state, { jornadas }) => [ ...jornadas ]
})

export const jornada_activa = createReducer(null, {
  [FIJAR_JORNADA_ACTIVA]: (state, { jornada }) =>  jornada
})

const initialDetalle = {
  pedidos: []
}

export const jornada_detalle = createReducer(initialDetalle, {
  [FIJAR_JORNADA_DETALLE]: (state, { pedidos }) => ({
    ...state,
    pedidos: pedidos
  })
})

export function pedidos_jornada_from_state(state) {
  return state.jornada_detalle.pedidos
}
import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import {INVENTORY} from '../../utils/Routes'
import moment from '../../utils/Moment'
import Loading from '../../components/Loading'

const INCREASE = 'INCREASE'
const DECREASE = 'DECREASE'
const REMOVED = 'REMOVED'
const CREATED = 'CREATED'

export default class InventoryDetail extends Component {
  state = {
    name: '',
    quantity: 0,
    unit: '',
    loading: true,
    removing: false
  }

  componentDidMount() {
    /*getInventoryItem(this.props.match.params.id).then(item => {
      this.setState({...item, loading: false})
    }).catch(this._onError)*/
  }

  remove = () => {
    this.setState({removing: true})
    /*removeItem(this.props.match.params.id).then(r => {
      this.props.history.goBack()
    }).catch(this._onError)*/
  }

  _onError = (e) => {
    console.log(e)
    this.setState({loading: false})
  }

  render() {
    if (this.state.loading) return <Loading />
    return (
      <div>
        <div className="p-3">
          <div className="mb-3 border-bottom">
            <i className="fal fa-box fa-2x mr-3"></i>{this.state.name}
          </div>
          <div className="mb-3 border-bottom">
            <i className="fal fa-sigma fa-2x mr-3"></i>{this.state.quantity}
          </div>
          <div className="mb-3 border-bottom">
            <i className="fal fa-union fa-2x mr-3"></i>{this.state.unit}
          </div>
          <button type="button" className="btn btn-danger btn-block" onClick={this.remove} disabled={this.state.removing}>
            {this.state.removing ? <i className="fas fa-circle-notch fa-spin"></i> : 'Eliminar'}
          </button>
          <Link to={INVENTORY} className="btn btn-link btn-block">Volver</Link>
        </div>
        <h5>Eventos</h5>
        <InventoryEvents id={this.props.match.params.id} />
      </div>
    )
  }
}

//TODO agregar paginacion
class InventoryEvents extends Component {
  state = {
    events: [],
    loading: true
  }

  componentDidMount() {
    /*fetchEvents(this.props.id).then(events => this.setState({events, loading: false})).catch(e => {
      console.error(e)
      this.setState({loading: false})
    })*/
  }

  render() {
    if (this.state.loading) return <Loading />
    return (
      <ul className="list-group list-group-flush">
        {this.state.events.map(Event)}
      </ul>
    )
  }
}

const Event = (e, i) => (
  <li key={i} className="list-group-item">
    <h5>{resolve(e.type)}</h5>
    <p className="mb-1">Cantidad {e.quantity}</p>
    <small className="text-muted">{moment(e.created).format('DD/MM/YYYY HH:mm:ss')}</small>
  </li>
)

function resolve(type) {
  switch (type) {
    case CREATED: return 'Creado'
    case INCREASE: return 'Aumentado en 1'
    case DECREASE: return 'Disminuido en 1'
    case REMOVED: return 'Eliminado'
    default: return type
  }
}

import { Component } from 'react';
import { ERROR } from './utils/Routes'

export default class WC extends Component {
  startLoading = () => this.setState({loading: true})
  stopLoading = () => this.setState({loading: false})

  onError = (e) => {
    console.error(e)
    this.setState({error: e.message ? e.message : e})
    this.stopLoading()
    if (this.props.history) {
      this.props.history.push(ERROR)
    }
  }

  clearError = () => this.setState({error: ''})

  onChange = (e)  => {
    let id = e.target.id
    let type = e.target.type
    let length = e.target.dataset.length
    let value = type === 'checkbox' ? e.target.checked : e.target.value

    if (length && value.length > length) return

    this.setState({[id]: value})
  }

  go = (path) => {
    if (this.props.history) {
      this.props.history.push(path)
    }
  }

  back = () => {
    if (this.props.history) {
      this.props.history.goBack()
    }
  }
}

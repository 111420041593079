import React, { Component } from 'react';
import {isEmpty} from '../../utils'

export default class InventoryCreate extends Component {
  state = {
    name: '',
    quantity: '',
    unit: '',
    loading: false
  }

  submit = (e) => {
    e.preventDefault()
    /*let item = {
      name: this.state.name.trim(),
      quantity: parseInt(this.state.quantity, 10),
      unit: this.state.unit.trim()
    }*/
    this.setState({loading: true})
    /*createInventoryItem(item).then(this._back).catch(e => {
      console.error(e)
      this.setState({loading: false})
    })*/
  }

  _back = () => this.props.history.goBack()
  _onChange = (e) => this.setState({[e.target.id]: e.target.value})
  _disabled = () => isNaN(this.state.quantity) || isEmpty(this.state.quantity) || isEmpty(this.state.name) || isEmpty(this.state.unit)

  render() {
    return (
      <div className="container pt-3">
        <form onSubmit={this.submit}>
          <div className="form-group d-flex align-items-center">
            <i className="fal fa-box fa-2x cs-small"></i>
            <input className="form-control" placeholder="Nombre" id="name" value={this.state.name} onChange={this._onChange} disabled={this.state.loading}/>
          </div>
          <div className="form-group d-flex align-items-center">
            <i className="fal fa-sigma fa-2x cs-small"></i>
            <input className="form-control" placeholder="Cantidad" id="quantity" value={this.state.quantity} onChange={this._onChange} disabled={this.state.loading}/>
          </div>
          <div className="form-group d-flex align-items-center">
            <i className="fal fa-union fa-2x cs-small"></i>
            <input className="form-control" placeholder="Unidad" id="unit" value={this.state.unit} onChange={this._onChange} disabled={this.state.loading}/>
          </div>
          <button className="btn btn-primary btn-block" disabled={this._disabled() || this.state.loading}>
            {this.state.loading ? <i className="fas fa-circle-notch fa-spin"></i> : 'Agregar al inventario'}
          </button>
          <button type="button" className="btn btn-link btn-block" onClick={this._back}>Volver</button>
        </form>
      </div>
    )
  }
}

import React, { Component } from 'react'

export default class ConfirmButton extends Component {
  state = {
    confirm: false
  }

  componentWillUnmount() {
    clearTimeout(this._timeout)
  }

  toggle = () => {
    const confirm = !this.state.confirm
    this.setState({ confirm })
    if (confirm) {
      this._timeout = setTimeout(() => this.setState({ confirm: false }), 1000)
    }
  }

  confirm = () => {
    this.toggle()
    this.props.onClick()
  }

  render() {
    const onClick = this.state.confirm ? this.confirm : this.toggle
    return (
      <button className="btn btn-link" type="button" onClick={onClick} disabled={this.props.disabled}>
        {this.state.confirm ? <i className="fas fa-check-circle text-success fa-lg"></i> : this.props.children}
      </button>
    )
  }
}
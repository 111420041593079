import isElectron from "is-electron";
const { ipcRenderer } = isElectron() && window.require('electron')

export function electron_imprimir(target, data) {
  const args = {
    target, data
  }

  ipcRenderer.invoke('print-action', args)
}


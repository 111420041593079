import React from 'react';
import WC from '../../WC'
import {RESTAURANT_MESA} from '../../utils/Routes'
//import moment from '../utils/Moment'

export default class Restaurant extends WC {
  state = {
    mesas: [{
      id: 1,
      mesero: '',
      orders: []
    }, {
      id: 2,
      mesero: '',
      orders: []
    }, {
      id: 3,
      mesero: '',
      orders: []
    }]
  }

  mesa = (id) => {
    this.go(RESTAURANT_MESA.replace(':id', id))
  }

  render() {
    return (
      <div>
        <ul className="list-group list-group-flush mb-5">
          {this.state.mesas.map((mesa, i) => (
            <li key={i} className="list-group-item cs-pointer" onClick={() => this.mesa(mesa.id)}>
              {i + 1} {mesa.mesero} {mesa.orders.length}
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

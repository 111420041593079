import { store as _store } from '../Store'

const host = process.env.NODE_ENV === 'production' ? 'https://api.clan28.cl' : 'http://192.168.0.11:8000'
//const host = 'https://api.clan28.cl'

export function get(path, headers) {
  return request(path, 'GET', undefined, headers)
}

export function post(path, body) {
  return request(path, 'POST', body)
}

export function put(path, body) {
  return request(path, 'PUT', body)
}

export function del(path, body) {
  return request(path, 'DELETE', body)
}

function request(path, method, body, headers) {
  let { token } = _store.getState().session

  return fetch(host + path, {
    method,
    headers: {
      'API-TOKEN': token,
      ...headers
    },
    body: body ? JSON.stringify(body) : undefined
  }).then(success)
}

function success(response) {
  if (response.status === 401) {
    return ({
      status: 'auth',
      data: 'No autorizado'
    })
  }
  if (response.status !== 200) {
    return Promise.reject(response)
  }
  return response.json()
}
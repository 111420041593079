import React, { Component } from 'react'
import _ from 'lodash'

import {fecha, now, numeric, str} from '../utils'

export function comandaTemplate(printer, data) {
  return new Promise((resolve, reject) => {
    if (!data || data === null)
      return reject('data no valida')

    let { nombre, direccion } = data.sucursal
    let order = data.order

    let products_total = order.products.reduce((t, p) => t + (p.price * p.quantity), order.deliveryCost)
    let tip = Math.ceil(products_total * 0.1)
    let total = products_total + tip

    printer.addText(_.padEnd(nombre, 29));
    printer.addText(fecha(order.datetime));
    printer.addFeedLine(1)
    printer.addText(direccion);
    printer.addFeedLine(2)
    printer.addTextDouble(true, false)
    printer.addText(`${order.user.name} ${str(order.user.apellido)}`);
    printer.addFeedLine(1)
    if (!isNaN(order.user.phone)) {
      printer.addText('+56-' + order.user.phone);
      printer.addFeedLine(1)
    }
    printer.addText(order.user.address);
    printer.addFeedLine(1)
    printer.addTextDouble(false, false)
    printer.addFeedLine(1)
    printer.addText(order.comment);
    printer.addFeedLine(1)
    printer.addText('------------------------------------------------\n');
    printer.addText('N°   Nombre                     Cantidad  Precio\n');
    printer.addText('------------------------------------------------\n');
    order.products.map(inLineProduct).forEach(p => printer.addText(p))
    //printer.addText('84   Promo 6 150 piezas             x1   $10.450\n');
    //printer.addText('112  Promo 3 40 piezas              x3    $9.990\n');
    printer.addFeedLine(1)
    printer.addText('Despacho');
    printer.addText(_.padStart('$' + numeric(order.deliveryCost), 40));
    printer.addFeedLine(1)
    printer.addText('------------------------------------------------\n');
    printer.addTextDouble(true, false)
    printer.addText('Total');
    printer.addText(_.padStart('$' + numeric(products_total), 19));
    printer.addFeedLine(1)
    printer.addTextDouble(false, false)
    printer.addFeedLine(1)
    printer.addText('Propina sugerida del 10%');
    printer.addText(_.padStart('$' + numeric(tip), 24));
    printer.addFeedLine(1)
    printer.addText('Total');
    printer.addText(_.padStart('$' + numeric(total), 43));
    printer.addFeedLine(2)

    printer.addCut(printer.CUT_FEED);
    printer.send();
    printer.onreceive = (response) => {
      console.log('printer', response)
      if (response.success) {
        resolve(response)
      } else {
        reject(response)
      }
    }
  })
}

function inLineProduct({index, title, quantity, price}) {
  title = title.length > 31 ? title.substring(0, 31) : title
  return _.padEnd(index, 4) + ' ' + _.padEnd(title, 31) +
    'x' + _.padEnd(quantity, 2) + _.padStart('$' + numeric(price), 9) + '\n'
}



export const ComandaPrintRender = React.forwardRef(({ data }, ref) => {
  if (!data) {
    return null
  }

  return (
    <div style={{ display: 'none' }}>
      <HtmlTemplate {...data} ref={ref} />
    </div>
  )
})

export class HtmlTemplate extends Component {
  render() {
    const { sucursal, order } = this.props
    const { nombre, direccion } = sucursal

    const products_total = order.products.reduce((t, p) => t + (p.price * p.quantity), order.deliveryCost)
    const tip = Math.ceil(products_total * 0.1)
    const total = products_total + tip

    return (
      <div style={{ paddingLeft: 16 }}>
        <div>
          <div>{nombre}</div>
          <div>{direccion}</div>
          <div>{fecha(order.datetime)}</div>
        </div>
        <div>
          <div>{`${order.user.name} ${str(order.user.apellido)}`}</div>
          {!isNaN(order.user.phone) && <div>{'+56-' + order.user.phone}</div>}
          <div>{order.user.address}</div>
          <div>{order.comment}</div>
          <div>-----------------------------------------------------</div>
          <table style={{ fontSize: '0.9em'}}>
            <thead>
              <tr>
                <th>N°</th>
                <th>Nombre</th>
                <th>Cantidad</th>
                <th>Precio</th>
              </tr>
            </thead>
            <tbody>
              {order.products.map(v => (
                <tr key={v._id}>
                  <td>{v.index}</td>
                  <td>{v.title}</td>
                  <td>{v.quantity}</td>
                  <td>${v.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div>-----------------------------------------------------</div>
          <div>Despacho ${numeric(order.deliveryCost)}</div>
          <h4>Total ${numeric(products_total)}</h4>
          <div>Propina sugerida del 10% ${numeric(tip)}</div>
          <div>Total ${numeric(total)}</div>
        </div>
      </div>
    )
  }
}
import React, {Component, useState} from 'react'
import { connect } from 'react-redux'
import { Button, Icon, Input, Item, Label, Modal } from 'semantic-ui-react'

import { galleryResource } from '../api'
import {clp, isEmpty, numeric} from '../utils'
import { marcar_producto_favorito } from "../actions";
import LazyLoad from 'react-lazyload'


export const ItemProducto = (props) => {
  const { _id, asset, title, detail, index, tags, price, quantity, products, favorito, extras,
    minus, plus, selectExtra, onClick, marcarFavorito } = props
    
  const onPlus = () => plus(_id)
  const onMinus = () => minus(_id)
  const imageSrc = isEmpty(asset) ? 'default.png' : galleryResource(asset)
  const onItemClick = () => onClick(_id)
  const favoritoClick = () => marcarFavorito(_id, !favorito)

  return (
    <Item>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 16}}>
        <Item.Image src={imageSrc} size="tiny" />
        {marcarFavorito && <Button size="large" icon={favorito ? "star" : "star outline"} style={{ marginTop: 8 }} onClick={favoritoClick} />}
      </div>
      <Item.Content>
        <Item.Header as={onClick ? "a" : ""} onClick={onClick && onItemClick}>{index} - {title}</Item.Header>
        <Item.Meta>
          <span className='cinema'>{detail}</span>
        </Item.Meta>
        <Item.Extra>
          <Label>{tags}</Label>
        </Item.Extra>
        <Item.Description>
          {products === null ? null : products.map((sub, i) => (
            <li key={i}><small>{sub.title} {sub.detail ? '(' + sub.detail + ')' : ''}</small></li>
          ))}
        </Item.Description>
        <Item.Description>{clp(price)}</Item.Description>
        {minus && plus && <Item.Description>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Button.Group size="big">
              <Button color="green" icon="plus" onClick={onPlus} type="button" />
              <Button.Or text={quantity} />
              <Button color="red" icon="minus" onClick={onMinus} type="button" />
            </Button.Group>
            <strong style={{ textAlign: 'right' }}>${numeric(price * quantity)}</strong>
          </div>
        </Item.Description>}
        {selectExtra && extras && extras.length > 0 &&
        <Item.Description>
          <Button.Group widths={extras.length}>
            {extras.map(v => (
              <Button type="button" key={v.value} primary={v.selected} onClick={() => selectExtra(v.value, _id)}>{v.value}</Button>
            ))}
          </Button.Group>
        </Item.Description>}
      </Item.Content>
      {onClick && <Item.Extra>
        <Button color="green" size="big" floated="right" icon="plus" onClick={onItemClick} content="Agregar" />
      </Item.Extra>}
    </Item>
  )
}

const ModalProductos = ({ visible, toggle, productos, marcar_producto, onClick }) => {
  const [filtro, setFiltro] = useState('')
  const onChange = (e) => setFiltro(e.target.value)
  const onProductoClick = (v) => {
    onClick(v)
    toggle()
    setFiltro('')
  }

  const data = productos.filter(p => filtrarProductos(p, filtro)).sort(sortCount).sort(sortFavorito)

  return (
    <Modal open={visible} onClose={toggle} onOpen={toggle} centered={false}>
      <Modal.Header><Input value={filtro} onChange={onChange} fluid placeholder="Buscar Producto" /></Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          {/*<a key={p._id} href="" onClick={e => this.addProduct(e, p)} className="list-group-item list-group-item-action pr-0 pl-0">*/}
          <LazyLoad>
            <Item.Group divided>
              {data.map(p => (
                <ItemProducto key={p._id} {...p} onClick={onProductoClick} marcarFavorito={marcar_producto} />
              ))}
            </Item.Group>
          </LazyLoad>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  productos: state.productos
})

export const ProductosModal = connect(mapStateToProps, { 
  marcar_producto: marcar_producto_favorito
 })(ModalProductos)


function filtrarProductos({ title, index }, filter) {
  if (filter.trim() === '') {
    return true
  }
  return title.toLowerCase().indexOf(filter) !== -1 ||
    (!isNaN(filter) && index === parseInt(filter, 10)) || filter === '*'
}

function sortCount(a, b) {
  return (b.count ? b.count : 0) - (a.count ? a.count : 0)
}

function sortFavorito(a, b) {
  return (b.favorito ? 1 : 0) - (a.favorito ? 1 : 0)
}

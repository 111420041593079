import React from 'react';
import WC from '../WC'
import {isEmpty} from '../utils'
import Loading from '../components/Loading'

const $ = window.$

export default class Employee extends WC {
  state = {
    name: '',
    employees: [],
    loading: false,
    employee: null
  }

  componentDidMount() {
    this.fetch()
  }

  submit = (e) => {
    e.preventDefault()
    this.setState({loading: true})
    //createEmployee({name: this.state.name.trim()}).then(this.fetch).catch(this.onError)
  }

  fetch = () => {
    this.setState({loading: true, name: ''})
    //fetchEmployees().then(employees => this.setState({employees, loading: false})).catch(this.onError)
  }

  removeModal = (employee) => {
    this.setState({employee})
    $('#removeEmployeeModal').modal('toggle')
  }

  remove = () => {
    $('#removeEmployeeModal').modal('toggle')
    //removeEmployee(this.state.employee.id).then(this.fetch).catch(this.onError)
  }

  _disabled = () => isEmpty(this.state.name) || this.state.loading

  render() {
    return (
      <div className="container pt-3">
        <RemoveEmployee employee={this.state.employee} remove={this.remove}/>
        <form onSubmit={this.submit}>
          <div className="form-group d-flex">
            <i className="fal fa-user fa-2x mr-2"></i>
            <input id="name" className="form-control" placeholder="Nombre del empleado" value={this.state.name} onChange={this.onChange}/>
          </div>
          <button className="btn btn-primary btn-block" disabled={this._disabled()}>Crear empleado</button>
        </form>
        {this.state.loading ? <Loading /> :
          <ul className="list-group list-group-flush mt-3">
            {this.state.employees.map((e, i) => (
              <li key={i} className="list-group-item">
                <EmployeeItem employee={e} remove={this.removeModal}/>
              </li>
            ))}
          </ul>
        }
      </div>
    )
  }
}

const EmployeeItem = ({employee, remove}) => (
  <div className="">
    <span className="mr-3">{employee.id}</span>
    <span><strong>{employee.name}</strong></span>
    <button type="button" className="close" aria-label="Close" onClick={() => remove(employee)}>
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
)

const RemoveEmployee = ({employee, remove}) => (
  <div className="modal fade" id="removeEmployeeModal" tabIndex="-1" role="dialog" aria-labelledby="removeModalLongTitle" aria-hidden="true">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-body">
          <p>Deseas eliminar al empleado <strong>{employee !== null ? employee.name : ''}</strong>?</p>
          <div className="d-flex align-items-end">
            <button type="button" className="btn btn-danger btn-block mr-1" onClick={remove}>Eliminar</button>
            <button type="button" className="btn btn-secondary btn-block" data-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
)

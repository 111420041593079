import React from 'react';
import { connect } from 'react-redux'
import WC from '../WC'
import { DASHBOARD } from '../utils/Routes'
import { fetchSucursales, setSucursal } from '../api'
import { sessionLogout } from '../actions/SessionActions'

class Stores extends WC {
  state = {
    sucursales: [],
    selected: '',
    loading: true
  }

  componentDidMount() {
    fetchSucursales(this.props.sucursales).then(({status, data}) => {
      console.log(status, data)
      if (status === 'success') {
        this.setState({sucursales: data, selected: data[0]._id, loading: false})
      } else {
        this.onError(data)
      }
    }).catch(this.onError)
  }

  submit = (e) => {
    e.preventDefault()
    setSucursal(this.state.selected).then(({status, data}) => {
      if (status === 'success') {
        this.props.history.push(DASHBOARD)
      } else {
        this.onError(data)
      }
    }).catch(this.onError)
  }

  logout = () => {
    this.props.sessionLogout().catch(this.onError)
  }

  _disabled = () => this.state.selected === ''

  render() {
    if (this.state.loading) return (
      <div className="cs-bg-primary cs-login text-white d-flex justify-content-center align-items-center">
        <i className="fas fa-circle-notch fa-spin fa-5x"></i>
      </div>
    )
    return (
      <div className="cs-bg-primary cs-login text-white">
        <div className="container pt-3">
          <div className="row justify-content-md-center">
            <div className="col-md-6">
              <div className="cs-logo  text-center">
                <img src="img/logo.png" height={200} alt="logo"/>
              </div>
              <form onSubmit={this.submit}>
                <div className="form-group">
                  <label htmlFor="selected">Selecciona la sucursal</label>
                  <select id="selected" className="form-control form-control-lg" value={this.state.selected} onChange={this.onChange}>
                    <option value="">------------------------------</option>
                    {this.state.sucursales.map((s, i) =>
                      <option key={i} value={s._id}>{s.name}</option>
                    )}
                  </select>
                </div>
                <button className="btn btn-primary btn-lg btn-block" disabled={this._disabled()}>Ingresar</button>
                <button type="button" className="btn btn-link btn-lg btn-block text-white" onClick={this.logout}>Salir</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  sucursales: state.session.sucursales
})

const mapDispatchToProps = (dispatch) => ({
  sessionLogout: () => dispatch(sessionLogout())
})

export default connect(mapStateToProps, mapDispatchToProps)(Stores)
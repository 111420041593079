const categorias = [{
  key: '01',
  value: '01',
  text: 'COSTOS DE ADMINISTRACION Y VENTAS',
  keys: ['costo_adm_vts']
}, {
  key: '02',
  value: '02',
  text: 'GASTOS DE PRODUCCION',
  keys: ['gst_produccion', 'gst_produccion_ind']
}, {
  key: '03',
  value: '03',
  text: 'REMUNERACIONES',
  keys: ['remuneraciones']
}]

export function labelConcepto(key) {
  const concepto = categorias.find(v => v.keys.find(k => k === key))
  return concepto ? concepto.text : key
}

export default categorias
import React from 'react'
import { Button, Header, Icon, Item, Label } from 'semantic-ui-react'
import { clp } from '../utils'
import { labelConcepto } from '../utils/Conceptos'


const GastoItem = ({ id, concepto, monto, cantidad, descripcion, caja, eliminar }) => {
  const onClick = () => eliminar(id)
  return (
    <Item>
      <Item.Content style={{width:'100%'}}>
        <Item.Header>{concepto.nombre_concepto}</Item.Header>
        <Item.Meta>{labelConcepto(concepto.tipo_concepto)}</Item.Meta>
        <Item.Description>{descripcion}</Item.Description>
        {caja && <Item.Extra><Label color="green">Caja</Label></Item.Extra>}
        <Item.Meta><Label>{cantidad}</Label>{clp(monto)}</Item.Meta>
        <Item.Description><Header as="h4">{clp(monto * cantidad)}</Header></Item.Description>
      </Item.Content>
      {eliminar && 
      <Item.Extra>
        <Button floated="right" color="red" onClick={onClick}>Eliminar</Button>
      </Item.Extra>}
    </Item>
  )
}

export default GastoItem
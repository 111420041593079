import React from 'react';
import WC from '../WC'
import Loading from '../components/Loading'
import moment from '../utils/Moment'
import ua from 'ua-parser-js'

const $ = window.$

export default class Profile extends WC {
  state = {
    sessions: [],
    loading: true
  }

  componentDidMount() {
    this.fetch().then(this.stopLoading).catch(this.onError)
  }

  revokeModal = (id) => {
    this._id = id
    $('#removeModal').modal('toggle')
  }

  revoke = () => {
    $('#removeModal').modal('toggle')
    this.startLoading()
    //removeSession(this._id).then(this.fetch).then(this.stopLoading).catch(this.onError)
  }

  //fetch = () => fetchSessions().then(sessions => this.setState({sessions}))

  render() {
    if (this.state.loading) return <Loading />
    return (
      <div className="container pt-3">
        <h4>Sesiones activas</h4>
        <ul className="list-group list-group-flush">
          {this.state.sessions.sort(created).map((s, i) => <Session key={i} session={s} revoke={this.revokeModal}/>)}
        </ul>
        <RemoveModal revoke={this.revoke}/>
      </div>
    )
  }
}

function created(a, b) {
  return moment(a.created).isBefore(b.created) ? 1 : -1
}

const Session = ({session, revoke}) => (
  <li className="list-group-item">
      <div className="d-flex justify-content-between">
        <div>
          <div className="d-flex">
            {session.current ? <div className="cs-circle-sm bg-primary mr-1"></div> : null}
            <label className="mb-2"><strong>{session.host.replace('::ffff:', '')}</strong></label>
          </div>
          <UserAgent agent={ua(session.user_agent)} />
        </div>
        <div className="d-flex flex-column justify-content-between">
          <div>
            <button type="button" className="close" aria-label="Close" onClick={() => revoke(session.id)}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <p className="text-muted">{moment(session.created).fromNow()}</p>
        </div>
      </div>
  </li>
)

const UserAgent = ({agent}) => (
  <div>
    <p className="mb-1">{agent.os.name} - {agent.os.version}</p>
    <p className="mb-1">{agent.browser.name} {agent.browser.version}</p>
    <small className="text-muted">{agent.engine.name} - {agent.engine.version}</small>
    {/*<p className="mb-1">{agent.device.model} - {agent.device.type} - {agent.device.vendor} </p>*/}

  </div>
)

const RemoveModal = ({revoke}) => (
  <div className="modal fade" tabIndex="-1" role="dialog" id="removeModal">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Eliminar</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>La sesion sera eliminada</p>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" className="btn btn-danger" onClick={revoke}>Eliminar</button>
        </div>
      </div>
    </div>
  </div>
)

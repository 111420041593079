import React from 'react'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withSize } from 'react-sizeme'
import { Menu, Icon, Label, Card, Button, Container, Message } from "semantic-ui-react";

import WC  from '../WC'
import { CREATE_ORDER, DETALLE_PEDIDO } from '../utils/Routes'
import { ALL, WORKING, DONE, DELIVERY, RETIRO,  statusColor } from '../utils/Status'
import { DeliveryModal, SolicitarPin, Loading, Header } from '../components'
import { anularPedido, pagarPedido, avanzarPedido } from '../api'
import { obtenerPedidos, limpiarError } from "../actions";
import { typeIcon, DELIVERY as DELIVERY_TYPE, typeLabel } from '../utils/OrderTypes';
import moment from "../utils/Moment";
import { toggleModal, isEmpty, is_nori, str } from '../utils';
import ConfirmButton from '../components/ConfirmButton';
import { MensajeError } from '../components/Mensajes';


//const STATUS = [ALL, WORKING, DONE, DELIVERY, LOCAL]

const BREAKPOINT = 780



class Dashboard extends WC {
  state = {
    pedidos: [],
    status: ALL,
    id_pedido: null,
    loading: false,
    current: null,
    modalPin: false,
    modalDelivery: false
  }

  componentDidMount() {
    this.fetch()
    this._timer = setInterval(this.fetch, 5000)
  }

  componentWillUnmount() {
    clearInterval(this._timer)
  }

  deliveryManSuccess = () => {
    this.startLoading()
    this.fetch().then(this.stopLoading).catch(this.onError)
  }

  fetch = () => this.props.obtenerPedidos()

  filter = (status) => {
    this.setState({status})
  }

  avanzar = (_id, status, type) => {
    //console.log('avanzar', _id, status, type)
    if ((status === DONE || status === DELIVERY) && type === DELIVERY_TYPE) {
      this.setState({ id_pedido: _id })
      this.toggleDelivery()
    } else {
      this.avanzarPedido(_id)
    }
  }

  avanzarPedido = (_id) => {
    avanzarPedido(_id).then(console.log).then(this.fetch).catch(console.error)
  }

  detalle = (_id) => {
    this.props.history.push(DETALLE_PEDIDO.replace(":id", _id))
  }

  toggleAnular = (_id) => {
    this._id = _id
    this.togglePin()
  }

  anular = (pin) => {
    anularPedido(this._id, pin).then(console.log).then(this.fetch).catch(console.error)
  }

  pagado = (_id) => {
    pagarPedido(_id).then(console.log).then(this.fetch).catch(console.error)
  }

  fijarRepartidor = (id) => {
    this.avanzarPedido(id)
  }

  statusLength = (status) => this.props.pedidos.filter(o => o.status === status).length

  togglePin = () => this.setState({ modalPin: !this.state.modalPin })
  toggleDelivery = () => this.setState({ modalDelivery: !this.state.modalDelivery })

  render() {
    const { id_pedido, status, loading } = this.state
    const { size, error } = this.props

    if (loading) return <Loading />
    
    const all = this.props.pedidos.length
    const working = this.statusLength(WORKING)
    const done = this.statusLength(DONE)
    const delivery = this.statusLength(DELIVERY)
    const retiro = this.statusLength(RETIRO)

    //const badge = status === 0 ? this.state.pedidos.length : this._statusLength(STATUS[status])
    return (
      <div>
        <Menu inverted icon="labeled" fixed="top" fluid widths={6}>
          <HeaderItem active={status === ALL} name="Todos" onClick={() => this.filter(ALL)} label={all} icon="bars" color="blue" width={size.width}/>
          <HeaderItem active={status === WORKING} name="Cocinando" onClick={() => this.filter(WORKING)} label={working} icon="fire" color="orange" width={size.width}/>
          <HeaderItem active={status === DONE} name="Terminados" onClick={() => this.filter(DONE)} label={done} icon="check" color="green" width={size.width}/>
          <HeaderItem active={status === DELIVERY} name="Despachos" onClick={() => this.filter(DELIVERY)} label={delivery} icon="motorcycle" color="violet" width={size.width}/>
          <HeaderItem active={status === RETIRO} name="Retiros" onClick={() => this.filter(RETIRO)} label={retiro} icon="home" color="brown" width={size.width}/>
          <Menu.Item onClick={() => this.go(CREATE_ORDER)}>
            <Icon name="plus" />
            Nuevo
          </Menu.Item>
        </Menu>
        <Container fluid>
          <MensajeError content={error} />
          <Card.Group itemsPerRow={size.width > BREAKPOINT + 300 ? 6 : 4} stackable>
            {this.props.pedidos.filter(o => o.status === status || status === ALL).sort(sortOrders).map(pedido => (
              <PedidoItem
                {...pedido}
                key={pedido._id}
                onClick={this.detalle}
                onPagarClick={this.pagado}
                onForwardClick={this.avanzar}
                onCancelClick={this.toggleAnular} />
            ))}
          </Card.Group>
        </Container>
        <DeliveryModal id_pedido={id_pedido} continuar={this.fijarRepartidor} visible={this.state.modalDelivery} toggle={this.toggleDelivery} />
        <SolicitarPin onClick={this.anular} visible={this.state.modalPin} toggle={this.togglePin} />
      </div>
    );
  }
}

const PedidoItem = ({ _id, index, type, user, datetime, channel, pay, status, onClick, onPagarClick, onCancelClick, onForwardClick }) => (
  <Card fluid>
    <Card.Content onClick={() => onClick(_id)}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 8 }}>
        <Label circular color={statusColor(status)} size="large">{index}</Label>
        <Icon name={typeIcon(type)} size="large" />
      </div>
      <Card.Header>{isEmpty(user.name) ? 'Anonimo' : `${user.name} ${str(user.apellido)}`}</Card.Header>
      <Card.Description>
        {isEmpty(user.address) ? '....' : user.address}
      </Card.Description>
      <Card.Meta>{moment(datetime).fromNow()}</Card.Meta>
      <Card.Meta>{typeLabel(type)}</Card.Meta>
      {pay && <Card.Meta>Pagado</Card.Meta>}
      <Channel value={channel} />
    </Card.Content>
    <Card.Content extra>
      <div className='ui tree buttons fluid'>
        <Button basic color='green' icon onClick={() => onPagarClick(_id)} disabled={pay}>
          <Icon name="money" />
        </Button>
        <Button basic color='red' icon onClick={() => onCancelClick(_id)}>
          <Icon name="minus circle" />
        </Button>
        <Button basic primary icon onClick={() => onForwardClick(_id, status, type)}>
          <Icon name="forward" />
        </Button>
      </div>
    </Card.Content>
  </Card>
)

const Channel = ({ value }) => {
  return is_nori(value) && <Label as='a' color='red' ribbon>App</Label>
}

const HeaderItem = ({ active, name, onClick, label, icon, color, width }) => (
  <Menu.Item active={active} name={name} onClick={onClick}>
    <Icon name={icon} />
    <div>
      <Label circular color={color}>{label}</Label>
      {width > 780 && <span>{name}</span>}
    </div>
  </Menu.Item>
)

function sortOrders(a, b) {
  return b.index - a.index
}

const mapStateToProps = ({ session, pedidos, estado }) => {
  const sucursal = session.sucursales.find(s => s.id === session.sucursal)
  return {
    pedidos,
    error: estado.error,
    nombre_sucursal: sucursal ? sucursal.nombre : 'Wasabi',
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ 
  obtenerPedidos,
  limpiarError
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withSize()(Dashboard))


import { fetchProductos, fetchPedidos, fetchJornadas, isSuccess, jornadaActiva, fetchEmpleados, marcarFavorito, fetchPedidosJornada } from '../api'
import { refrescarSession, sessionLogin, sessionLogout } from './SessionActions'
import { SET_PRODUCTOS, SET_PEDIDOS, FIJAR_ERROR, LIMPIAR_ERROR, FIJAR_JORNADAS,
  FIJAR_JORNADA_ACTIVA, FIJAR_EMPLEADOS, FIJAR_PRODUCTO_FAVORITO, FIJAR_WINDOWS_SIZE, FIJAR_JORNADA_DETALLE,
   FIJAR_PRINTER_TARGET, FIJAR_PRINTER_QUANTITY, FIJAR_PRINTER_MODEL, TOGGLE_PRINTER_SSL, FIJAR_PRINTER_MODO } from './Types'

export function initialSetup() {
  return (dispatch) => {
    const sessionPromise = refrescarSession()(dispatch)
    const empleados = fetchEmpleados().then(fijarEmpleados).then(dispatch)
    const productosPromise = fetchProductos().then(arraySuccess).then(productosAction).then(dispatch)
    return Promise.all([productosPromise, sessionPromise, empleados]).catch(e => {
      console.log("error", e)
      dispatch(fijarError(e.message))
    })
  }
}

export function marcar_producto_favorito(_id, favorito) {
  return async (dispatch) => {
    try {
      dispatch({
        type: FIJAR_PRODUCTO_FAVORITO,
        _id, favorito
      })
      await marcarFavorito(_id, favorito)
    } catch (e) {
      console.error(e)
    }
  }
}

export function limpiarError() {
  return {
    type: LIMPIAR_ERROR
  }
}

function fijarEmpleados(res) {
  if (isSuccess(res)) {
    return {
      type: FIJAR_EMPLEADOS,
      empleados: res.data
    }
  } else {
    return fijarError(res.data)
  }
}

function arraySuccess(res) {
  return res.status === 'success' ? res.data : []
}

function productosAction(productos) {
  //console.log(productos)
  return {
    type: SET_PRODUCTOS,
    productos
  }
}

function obtenerPedidos() {
  return (dispatch) => fetchPedidos().then(res => {
    //console.log(res)
    if (isSuccess(res)) {
      dispatch({
        type: SET_PEDIDOS,
        pedidos: res.data
      })
      dispatch(fijarError(''))
    } else {
      dispatch({
        type: SET_PEDIDOS,
        pedidos: []
      })
      dispatch(fijarError(res.data))
    }
  })
}

function fijarError(error) {
  return {
    type: FIJAR_ERROR, error
  }
}

export function fijarJornadaActiva() {
  return (dispatch) => jornadaActiva().then(res => {
    console.log('jornada activa', res)
    if (isSuccess(res)) {
      return {
        type: FIJAR_JORNADA_ACTIVA,
        jornada: res.data
      }
    } else {
      dispatch(fijarError(res.data))
      return {
        type: FIJAR_JORNADA_ACTIVA,
        jornada: null
      }
    }
  }).then(dispatch)
}

export function fijarJornadas() {
  return (dispatch) => fetchJornadas().then(res => {
    if (isSuccess(res)) {
      return {
        type: FIJAR_JORNADAS,
        jornadas: res.data
      }
    }
    return fijarError(res.data)
  }).then(dispatch)
}

export function fijar_window_size() {
  return {
    type: FIJAR_WINDOWS_SIZE
  }
}

export function fijar_jornada_detalle(id) {
  return async (dispatch) => {
    try {
      const res = await fetchPedidosJornada(id)
      if (isSuccess(res)) {
        dispatch({
          type: FIJAR_JORNADA_DETALLE,
          pedidos: res.data
        })
      }
    } catch (e) {
      console.log(e)
      dispatch(fijarError(e.message))
    }
  }
}

export function fijar_printer_target(target) {
  return {
    type: FIJAR_PRINTER_TARGET,
    target
  }
}

export function fijar_printer_quantity(quantity) {
  return {
    type: FIJAR_PRINTER_QUANTITY,
    quantity
  }
}

export function fijar_printer_model(model) {
  return {
    type: FIJAR_PRINTER_MODEL,
    model
  }
}

export function toggle_printer_ssl() {
  return {
    type: TOGGLE_PRINTER_SSL,
  }
}

export function fijar_printer_modo(modo) {
  return {
    type: FIJAR_PRINTER_MODO,
    modo
  }
}

export {
  sessionLogin, sessionLogout, obtenerPedidos
}
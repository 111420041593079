import React, { useState } from 'react';
import { Container, Icon, Item, Menu } from "semantic-ui-react";

import { connect } from 'react-redux';
import { fijarSucursal, galleryResource, isSuccess } from '../api';
import { isEmpty } from 'lodash';
import { DASHBOARD } from '../utils/Routes';
import { initialSetup } from '../actions';


const Sucursales = ({ sucursales, id_sucursal, history, setup }) => {
  const [busy, setBusy] = useState(false)
  const actual = sucursales.find(v => v.id === id_sucursal)
  const data = sucursales.filter(v => v.id !== id_sucursal)

  const onClick = async (id) => {
    try {
      setBusy(true)
      const res = await fijarSucursal(id)
      if (isSuccess(res)) {
        await setup()
        history.push(DASHBOARD)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setBusy(false)
    }
  }

  return (
    <div>
      <Menu inverted fixed="top" fluid icon="labeled">
        <Menu.Item>
          <Icon name="exchange" />
          Cambiar de sucursal
        </Menu.Item>
      </Menu>
      <Container>
        <Item.Group divided>
          <ItemSucursal { ...actual } active />
          {data.map(v => <ItemSucursal key={v.id} { ...v } onClick={onClick} busy={busy} />)}
        </Item.Group>
      </Container>
    </div>
  )
}

const ItemSucursal = ({ id, nombre, thumbnail, active, onClick }) => {
  const image = isEmpty(thumbnail) ? 'default.png' : galleryResource(thumbnail)
  const onItemClick = () => onClick(id)

  return (
    <Item onClick={onClick && onItemClick}>
      <Item.Image size="tiny" src={image} />
      <Item.Content verticalAlign="middle">
        <Item.Header as="a">{nombre}</Item.Header>
        {active && <Item.Extra>Sucursal actual</Item.Extra>}
      </Item.Content>
    </Item>
  )
}

const mapStateToProps = (state) => ({
  sucursales: state.session.sucursales.sort(sortSucursal),
  id_sucursal: state.session.sucursal
})

function sortSucursal(a, b) {
  return a.nombre < b.nombre ? -1 : 1
}

export default connect(mapStateToProps, { setup: initialSetup })(Sucursales)
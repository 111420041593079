import numeral from './Numeral'
import moment from 'moment'
import { CHANNEL_NORI, PRINTER_MODO_BROWSER } from './Constants';


const IP_REGEX = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
const EMAIL_REG = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
const RUT_REG = /^[0-9]+[-|‐]{1}[0-9kK]{1}$/
export const MAX_NUMBER = 999999999999999

const $ = window.$


export function toggleModal(id) {
  $('#' + id).modal('toggle')
}

export function isEmpty(s) {
  return !s || s === null || (s.trim ? s.trim().length === 0 : false)
}

export function int(s) {
  return parseInt(s, 10)
}

export function numeric(value) {
  return numeral(value).format('0,0')
}


export function testEmail(email) {
  return EMAIL_REG.test(email);
}

export function is_ip(value) {
  return IP_REGEX.test(value)
}

export function now() {
  return moment().format('DD/MM/YYYY HH:mm:ss')
}

export function clp(v) {
  return numeral(v).format('$0,0')
}

export function rutValido(value) {
  if (!value || value === null || value.length < 2) {
    return false
  }

  let dv = value[value.length - 1].toLowerCase()
  let digitos = value.substring(0, value.length - 1)
  let rut = digitos + '-' + dv
// eslint-disable-next-line
  return RUT_REG.test(rut) && dv == calcula_dv(digitos);
}

export function calcula_dv(rut) {
  let M = 0, S = 1;
  let T = int(rut);
// eslint-disable-next-line
  for (; T != 0; T = Math.floor(T / 10)) {
    S = (S + T % 10 * (9 - M++ % 6)) % 11;
  }

  return S > 0 ? S - 1 : "k";
}

export function str(v) {
  return v && v !== null ? v.toString() : ''
}

export function is_nori(channel) {
  return channel === CHANNEL_NORI
}

export function reduceProducts(t, v) {
  return t + (v.price * v.quantity)
}

export function fecha(datetime) {
  return moment(datetime).format('DD/MM/YYYY - HH:mm') 
}

export function is_printer_browser(modo) {
  return modo === PRINTER_MODO_BROWSER
}

import React, { Component } from 'react';
import { Menu, Icon, Label, Card, Button, Container, Form, Input, Select, Checkbox, Message } from "semantic-ui-react";
import ReactToPrint from 'react-to-print';
import moment from "moment";
import isElectron from "is-electron";

import { version } from "../../package.json";
import epson from '../lib/EpsonDevice'
import { electron_imprimir } from "../lib/Electron";
import { comandaTemplate, ComandaPrintRender, HtmlTemplate } from '../lib/EpsonTemplate'
import { sessionLogout } from '../actions/SessionActions'
import { connect } from 'react-redux';
import { MensajeError } from '../components/Mensajes';
import { fijar_printer_model, fijar_printer_quantity, fijar_printer_target,
  toggle_printer_ssl, fijar_printer_modo } from '../actions';
import { isEmpty, is_ip, is_printer_browser } from '../utils';
import { DASHBOARD } from '../utils/Routes';
import { PRINTER_MODO_BROWSER, PRINTER_MODO_EPSON } from '../utils/Constants';



const SampleData = {
  sucursal: {
    nombre: 'Komanda',
    direccion: 'Calle falsa 748'
  },
  order: {
    user: {
      phone: 99852746,
      name: 'Comanda de prueba',
      address: 'Los pimienta 3345'
    },
    comment: 'envueltos en palta',
    products: [{
      _id: '1',
      index: 332,
      title: 'Promo 3 (Piezas Fritas)',
      price: 11000,
      quantity: 1
    }, {
      _id: '2',
      index: 5,
      title: 'SAKE MAKI',
      price: 1600,
      quantity: 4
    }],
    deliveryCost: 1500
  }
}

const Modos = [
  { key: '1', text: 'Epson', value: PRINTER_MODO_EPSON },
  { key: '2', text: 'Impresora predeterminada (Experimental)', value: PRINTER_MODO_BROWSER }
]

const QuantityOptions = [
  { key: '1', text: '1', value: 1},
  { key: '2', text: '2', value: 2},
  { key: '3', text: '3', value: 3},
  { key: '4', text: '4', value: 4},
  { key: '5', text: '5', value: 5},
]

const MODELOS = [
  { key: '1', text: 'TM-M10', value: 'TM_M10' },
  { key: '2', text: 'TM-M30', value: 'TM_M30' },
  { key: '3', text: 'TM-M30II', value: 'TM_M30II' },
  { key: '4', text: 'TM-M50', value: 'TM_M50' },
  { key: '5', text: 'TM-P20', value: 'TM_P20' },
  { key: '6', text: 'TM-P60', value: 'TM_P60' },
  { key: '7', text: 'TM-P60II', value: 'TM_P60II' },
  { key: '8', text: 'TM-P80', value: 'TM_P80' },
  { key: '9', text: 'TM-T20', value: 'TM_T20' },
  { key: '10', text: 'TM-T60', value: 'TM_T60' },
  { key: '11', text: 'TM-T70', value: 'TM_T70' },
  { key: '12', text: 'TM-T81', value: 'TM_T81' },
  { key: '13', text: 'TM-T82', value: 'TM_T82' },
  { key: '14', text: 'TM-T83', value: 'TM_T83' },
  { key: '15', text: 'TM-T83III', value: 'TM_T83III' },
  { key: '16', text: 'TM-T88', value: 'TM_T88' },
  { key: '17', text: 'TM-T90', value: 'TM_T90' },
  { key: '18', text: 'TM-T100', value: 'TM_T100' },
  { key: '19', text: 'TM-U220', value: 'TM_U220' },
  { key: '20', text: 'TM-U330', value: 'TM_U330' },
  { key: '21', text: 'TM-L90', value: 'TM_L90' },
  { key: '22', text: 'TM-H6000', value: 'TM_H6000' },
]

class Configuration extends Component {
  state = {
    printing: false,
    ssl: false,
    error: ''
  }

  printSample = (printer) => {
    comandaTemplate(printer, SampleData)
      .then(() => this.setState({printing: false}))
      .catch(this._onPrintError)
  }

  _onPrintError = (e) => {
    console.error(e)
    this.setState({printing: false, error: e})
  }

  testPrinter = () => {
    if (isElectron()) {
      electron_imprimir(this.props.target, SampleData)
    } else {
      this.setState({printing: true, error: ''})
      epson.print(this.props.target, this.props.ssl, true).then(this.printSample).catch(this._onPrintError)
    }
  }

  testBrowserPrinter = () => {
    this.printPedidoRef.handlePrint()
  }

  logout = () => {
    this.props.sessionLogout()
    this.props.history.push(DASHBOARD)
  }

  onModeloChange = (e, { value }) => this.props.fijar_printer_model(value)
  onCantidadChange = (e, { value }) => this.props.fijar_printer_quantity(value)
  onTargetChange = (e) => this.props.fijar_printer_target(e.target.value)
  onSslChange = () => this.props.toggle_printer_ssl()
  onModoChange = (e, { value }) => this.props.fijar_printer_modo(value)

  render() {
    const { target, quantity, model, ssl, modo } = this.props
    const disabled = this.state.printing || !is_ip(target)
    const is_browser_mode = is_printer_browser(modo)
    const is_electron = isElectron()

    return (
      <div>
        <Menu inverted icon="labeled" fixed="top" fluid>
          <Menu.Item>
            <Icon name="cog"  />
            Ajustes
          </Menu.Item>
          <Menu.Item position="right" onClick={this.logout}>
            <Icon name="log out" />
            Cerrar Sesion
          </Menu.Item>
        </Menu>
        <Container>
          <MensajeError content={this.state.error} />
          <Form>
            {!is_electron && <Form.Field control={Select} label="Modo de impresion" options={Modos} value={modo} onChange={this.onModoChange}/>}
            {/*<Form.Field control={Select} label="Modelo Impresora" options={MODELOS} value={model} onChange={this.onModeloChange}/>*/}
            {!is_browser_mode && <Form.Field control={Input} label="Dirección IP de la impresora" value={target} onChange={this.onTargetChange} />}
            {/*<Form.Field control={Select} label="Cantidad de copias" options={QuantityOptions} value={quantity} onChange={this.onCantidadChange} />*/}
            {!is_electron && !is_browser_mode && <Form.Field control={Checkbox} label="Usar SSL" checked={ssl} onChange={this.onSslChange} />}
            {!is_electron && is_ip(target) && !is_browser_mode &&
            <Message color="yellow">
              Si no se puede conectar con la impresora hay que aceptar el certificado presionando <a href={`https://${target}`} target="blank">Aqui</a>
            </Message>}
            {is_browser_mode &&
            <Message color="yellow">
              Esta opción usa la impresora configurada en el PC
            </Message>
            }
            <Button primary onClick={is_browser_mode ? this.testBrowserPrinter : this.testPrinter} disabled={disabled} loading={this.state.printing}>Probar Impresora</Button>
          </Form>
          <div className="cs-text-center">
            <p>Komanda Virtual {version} - {moment().format('YYYY')}</p>
          </div>
        </Container>
        <ReactToPrint ref={el => (this.printPedidoRef = el)} content={() => this.pedidoRef} />
        <ComandaPrintRender ref={(el) => (this.pedidoRef = el)} data={SampleData} />
      </div>
    )
  }
}





const mapStateToProps = (state) => ({
  target: state.ajustes.printer_target,
  model: state.ajustes.printer_model,
  quantity: state.ajustes.printer_quantity,
  ssl: state.ajustes.printer_ssl,
  modo: state.ajustes.printer_modo
})

export default connect(mapStateToProps, { sessionLogout, fijar_printer_model,
  toggle_printer_ssl, fijar_printer_quantity, fijar_printer_target, fijar_printer_modo })(Configuration)
export const DASHBOARD = '/'
export const ERROR = '/errors'
export const STORES = '/stores'
export const CREATE_ORDER = '/nueva/orden'
export const DETALLE_PEDIDO = '/pedido/:id'
export const CONFIGURATION = '/config'
export const EMPLOYEE = '/empleados'
export const INVENTORY = '/inventario'
export const INVENTORY_CREATE = '/inventario/crear'
export const INVENTORY_DETAIL = '/inventario/detalle/:id'
export const JORNADAS = '/jornadas'
export const JORNADAS_DETAIL = '/jornadas/detalle/:id'
export const JORNADAS_RESUMEN = '/jornadas/resumen/:id'
export const COMPRAS = '/compras'
export const INGRESAR_COMPRA = '/compras/ingresar'
export const RESTAURANT = '/restaurant'
export const RESTAURANT_MESA = '/restaurant/mesa/:id'
export const PROFILE = '/profile'
export const SUCURSALES = '/sucursales'

/*export default {
  [DASHBOARD]: {
    title: 'Ordenes Pendientes'
  }
}*/

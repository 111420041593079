import React, { useState } from 'react'
import { validarPin, isSuccess } from "../api";
import { int } from '../utils';
import { Button, Form, Message, Modal } from 'semantic-ui-react';



export default ({ visible, toggle, onClick }) => {
  const [pin, setPin] = useState('')
  const [error, setError] = useState(false)
  const [busy, setBusy] = useState(false)
  const onChange =(e) => setPin(e.target.value)
  const continuar = async () => {
    try {
      setError(false)
      setBusy(true)
      const numPin = int(pin)
      const res = await validarPin(numPin)
      if (isSuccess(res) && res.data) {
        onClick(numPin)
        toggle()
      } else {
        setError(true)
      }
    } catch (e) {
      console.error(e)
      setError(true)
    } finally {
      setBusy(false)
    }
  }

  return (
    <Modal open={visible} onClose={toggle} onOpen={toggle}  size="mini">
      <Modal.Header>Autorizacion</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form>
            <Form.Input id="pin_clave" autoComplete="off" value={pin} onChange={onChange} label="Ingresa el código de acceso" type="password" required disabled={busy} />
          </Form>
        </Modal.Description>
        {error && <CodigoInvalido />}
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={toggle} content="Volver" />
        <Button content="Continuar" onClick={continuar} positive loading={busy} disabled={busy} />
      </Modal.Actions>
    </Modal>
  )
}

const CodigoInvalido = () => (
  <Message negative>
    <Message.Header>Error de autorización</Message.Header>
    <p>El código ingresado no es valido</p>
  </Message>
)
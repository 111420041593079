import React, { Component } from 'react';
import {INVENTORY_CREATE, INVENTORY_DETAIL} from '../../utils/Routes'
import Loading from '../../components/Loading'

export default class Inventory extends Component {
  state = {
    inventory: [],
    loading: true
  }

  componentDidMount() {
    this._refresh()
  }

  create = () => this.props.history.push(INVENTORY_CREATE)
  detail = (id) => this.props.history.push(INVENTORY_DETAIL.replace(':id', id))

 // _refresh = () => fetchInventory().then(inventory => this.setState({inventory, loading: false})).catch(this._onError)
  _onError = (e) => {
    console.error(e)
    this.setState({loading: false})
  }

  render() {
    if (this.state.loading) return <Loading />
    return (
      <div className="container pt-3">
        {this.state.inventory.length === 0 ? 'El inventario esta vacio.' :
        <ul className="list-group list-group-flush">
          {this.state.inventory.sort(sort).map((item, i) => <Item key={i} item={item} onClick={this.detail} refresh={this._refresh}/>)}
        </ul>
        }
        <div className="fixed-bottom d-flex justify-content-end">
          <button className="btn btn-primary cs-fab mr-4 mb-4" type="button" onClick={this.create}>
            <i className="fal fa-plus fa-2x"></i>
          </button>
        </div>
      </div>
    )
  }
}

function sort(a, b) {
  return b.id - a.id
}

class Item extends Component {
  state = {
    loading: false
  }

  _increase = () => {
    this.setState({loading: true})
    /*increaseItem(this.props.item.id).then(this.props.refresh).then(() => {
      this.setState({loading: false})
    }).catch(e => {
      console.error(e)
      this.setState({loading: false})
    })*/
  }

  _decrease = () => {
    if (this.props.item.quantity <= 0) return
    this.setState({loading: true})
    /*decreaseItem(this.props.item.id).then(this.props.refresh).then(() => {
      this.setState({loading: false})
    }).catch(e => {
      console.error(e)
      this.setState({loading: false})
    })*/
  }

  render() {
    let item = this.props.item
    return (
      <li className="list-group-item">
        {this.state.loading ? <ItemLoading /> :
          <div className="d-flex justify-content-between align-items-center">
            <div className="cs-pointer w-100" onClick={() => this.props.onClick(item.id)}>
              <h5>{item.name}</h5>
              <span>{item.quantity} {item.unit}</span>
            </div>
            <div className="btn-group">
              <button className="btn btn-outline-success cs-btn-small" type="button" onClick={this._increase}><i className="fas fa-plus"></i></button>
              <button className="btn btn-outline-danger cs-btn-small" type="button" onClick={this._decrease}><i className="fas fa-minus"></i></button>
            </div>
          </div>
        }
      </li>
    )
  }
}

const ItemLoading = () => (
  <div className="text-center p-2">
    <i className="fas fa-circle-notch fa-spin fa-2x"></i>
  </div>
)

import React, { Component } from 'react';

import {int, isEmpty, MAX_NUMBER, clp} from '../../utils'
import { fetchConceptos, isSuccess, crearGasto } from '../../api';
import categorias from '../../utils/Conceptos'
import { Button, Checkbox, Container, Form, Header, Icon, Input, Menu, Select } from 'semantic-ui-react';


const defaultId = '01'

export default class CrearGasto extends Component {
  state = {
    id_categoria: defaultId,
    conceptos: [],
    concepto: '',
    descripcion: '',
    monto: '',
    cantidad: '1',
    caja: true,
    loading: false
  }

  componentDidMount() {
    fetchConceptos().then(res => {
      if (isSuccess(res)) {
        this.setState({ conceptos: res.data })
      }
    })
  }

  create = (e) => {
    e.preventDefault()
    const gasto = {
      descripcion: this.state.descripcion.trim(),
      monto: int(this.state.monto),
      cantidad: int(this.state.cantidad),
      concepto: this.state.conceptos.find(c => c.id_concepto === int(this.state.concepto)),
      caja: this.state.caja
    }
    console.log(gasto)
    this.loading()
    crearGasto(gasto).then(res => {
      console.log(res)
      if (isSuccess(res)) {
        this.volver()
      }
    }).catch(console.error).finally(this.loading)
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value})
  }

  onChecked = (e) => this.setState({ [e.target.id]: e.target.checked })

  onCategoria = (e, { value }) => this.setState({ id_categoria: value })
  onConcepto = (e, { value }) => this.setState({ concepto: value })

  volver = () => this.props.history.goBack()
  loading = () => this.setState({ loading: !this.state.loading })

  render() {
    const { monto, cantidad, conceptos, id_categoria, concepto, descripcion, caja, loading } = this.state
    const categoria = categorias.find(c => c.value === id_categoria)
    const conceptosOptions = conceptos.filter(c => categoria.keys.some(k => c.tipo_concepto === k)).map(mapConcepto)
    let total = int(monto) * int(cantidad)
    total = isNaN(total) ? 0 : total

    const disabled = isEmpty(descripcion) || isNaN(monto) || concepto === '' ||
      isNaN(cantidad) || total > MAX_NUMBER || total === 0 || loading

    return (
      <div>
        <Menu size='massive' inverted icon="labeled" fixed="top" fluid>
          <Menu.Item onClick={this.volver}>
            <Icon name="chevron left" size="big" />
            Volver
          </Menu.Item>
          <Menu.Item position="right" onClick={this.create} disabled={disabled}>
            <Icon name="save" size="big" />
            Guardar
          </Menu.Item>
        </Menu>
        <Container>
          <Form size="big">
            <Form.Field control={Select} label="Categoria" options={categorias} value={id_categoria} onChange={this.onCategoria} />
            <Form.Field control={Select} label="Concepto" options={conceptosOptions} value={concepto} onChange={this.onConcepto} />
            <Form.Field control={Input} label="Descripción" value={descripcion} id="descripcion" onChange={this.onChange} />
            <Form.Field control={Input} label="Cantidad" value={cantidad} id="cantidad" onChange={this.onChange} type="number" />
            <Form.Field control={Input} label="Monto" value={monto} id="monto" onChange={this.onChange} type="number" />
            <Form.Field control={Checkbox} label="Incluir en caja" checked={caja} onChange={this.onChecked} id="caja" />
            <Button primary fluid size="big" onClick={this.create} loading={loading} disabled={disabled}>Guardar ({clp(total)})</Button>
          </Form>
        </Container>
      </div>
    )
  }
}

const mapConcepto = (c) => ({ key: c.id_concepto, value: c.id_concepto, text: c.nombre_concepto })
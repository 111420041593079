import React, {Component} from 'react'
import { connect } from 'react-redux'
import { sessionLogout } from '../actions/SessionActions'

import './Header.css'


class Header extends Component {
  navigate = (e, to) => {
    e.preventDefault()
    window.$('#navbarNav').collapse('hide');
    if (to !== this.props.history.location.pathname) {
      this.props.history.push(to)
    } else {
      window.location.reload()
    }
  }

  goBack = () => this.props.history.goBack()

  render() {
    const { titulo, left, center, right, back } = this.props
    const _left = back ? <Back onClick={this.goBack} /> : left 
    const _center = center ? center : <Titulo value={titulo} />

    return (
      <nav className="cs-header fixed-top cs-bg-primary-dark shadow overflow-hidden">
        <div className="cs-header-body">
          <div>{_left}</div>
          <div className="cs-header-center">{_center}</div>
          <div>{right}</div>
        </div>
      </nav>
    )
  }
}

const Back = ({ onClick }) => (
  <div className="cs-btn cs-pointer pt-4 pb-3" onClick={onClick}>
    <i className="far fa-angle-left fa-2x cs-primary-text" ></i>
  </div>
)
const Titulo = ({ value }) => <h4 className="pl-3 pt-4 cs-primary-text">{value}</h4>

/*

        <button className="btn btn-link navbar-brand" type="button" onClick={refresh}>{nombre}</button>
        <button id="toggler" className="navbar-toggler" type="button" data-toggle="collapse"
          data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            {Menu.map((option, i) => (
              <li key={i} className={"nav-item" + this._active(option.path)}>
                <a className="nav-link" href="" onClick={e => this.navigate(e, option.path)}>{option.label}</a>
              </li>
            ))}
            {tieneSucursales &&
              <li className={"nav-item"}>
                <a className="nav-link" type="button" onClick={toggleSucursales}>Sucursal</a>
              </li>
            }
          </ul>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item active">
              <div className="nav-link d-flex align-items-center">
                <div className="mr-1"><i className="fas fa-user-circle fa-lg"></i> {this.props.email}</div>
<div className="cs-pointer" onClick={this.logout}>
  <i className="fas fa-sign-out-alt fa-lg"></i>
</div>
              </div >
            </li >
          </ul >
        </div >
        */


const mapStateToProps = ({ session }) => ({
  email: session.email,
  sucursal: session.sucursales.find(s => s.id === session.sucursal),
  tieneSucursales: session.sucursales.length > 1
})

const mapDispatchToProps = (dispatch) => ({
  sessionLogout: () => dispatch(sessionLogout())
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
import React, { useState } from 'react'

import { fijarRepartidor, isSuccess } from '../api';
import { Button, Form, Modal, Select } from 'semantic-ui-react';
import { MensajeError } from './Mensajes';
import { connect } from 'react-redux';

//ordernar por nombre conectar redux
const DeliveryModal = ({ visible, toggle, empleados, id_pedido, continuar }) => {
  const [busy, toggleBusy] = useState(false)
  const [error, setError] = useState('')
  const [repartidor, setRepartidor] = useState(empleados.length > 0 ? empleados[0].email : null)
  const options = empleados.map(v => ({ key: v.email, value: v.email, text: v.nombre }))
  const onChange = (e, { value }) => setRepartidor(value)
  const disabled = repartidor === null || busy

  const onClick = async () => {
    try {
      toggleBusy(true)
      setError('')
      const deliveryMan = empleados.find(v => v.email === repartidor)
      const res = await fijarRepartidor(id_pedido, deliveryMan)
      if (isSuccess(res)) {
        continuar(id_pedido)
        toggle()
      } else {
        setError(res.data)
      }
    } catch (e) {
      console.error(e)
      setError(e.message)
    } finally {
      toggleBusy(false)
    }
  }

  return (
    <Modal open={visible} onClose={toggle}>
      <Modal.Header>Selecciona al Repartidor</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field control={Select} label="Repartidor" required options={options} value={repartidor} onChange={onChange} />
          <MensajeError content={error} />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button disabled={disabled} primary loading={busy} onClick={onClick} type="button">Asignar Repartidor</Button>
      </Modal.Actions>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  empleados: state.empleados
})

export default connect(mapStateToProps)(DeliveryModal)